@charset "UTF-8";
@import url(../node_modules/toastr/build/toastr.min.css);
.assets-page {
  padding-bottom: 80px; }

.Polaris-Pagination {
  margin-left: auto; }

.btns-container {
  text-align: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: 0px; }

.blur {
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 1s linear;
  transition: 1 linear; }

.btns-container {
  text-align: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: 0px; }

.blur {
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 1s linear;
  transition: 1 linear; }

.aa-Panel {
  width: 400px !important; }

.aa-SubmitButton {
  display: none; }

.aa-ClearIcon {
  display: none; }

.aa-Form {
  border: none !important;
  background-color: transparent !important; }

.aa-Form:focus-within {
  border: none !important;
  box-shadow: none !important; }

.aa-ItemIcon {
  display: none !important; }

.aa-ItemActions {
  display: none !important; }

.aa-List {
  z-index: 99995;
  padding-top: 15px !important; }

.aa-Source::before {
  font-weight: bold;
  padding-bottom: 25px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 65px;
  display: block;
  font-weight: bold;
  font-family: arial, sans-serif; }

html[lang="en"] .aa-Source::before {
  content: 'Recommended Keywords'; }

html[lang="jp"] .aa-Source::before {
  content: 'おすすめキーワード'; }

.aa-List::before {
  position: absolute;
  left: 0;
  top: 0px;
  height: 1px;
  background: lightgray;
  content: "";
  width: 400px;
  display: block;
  margin-left: -20px !important; }

.aa-ItemContent mark {
  font-weight: normal !important; }

.aa-PanelLayout {
  padding-left: 20px !important;
  padding-bottom: 15px !important; }

.asset-card-container {
  width: 200px;
  text-align: left; }
  .asset-card-container .Polaris-Card {
    border-radius: 10px;
    box-shadow: none; }
  .asset-card-container:hover {
    transform: scale(1.03);
    transition-duration: 0.4s; }
    .asset-card-container:hover .card-overlay-action-menu {
      display: block; }
  .asset-card-container .card-overlay-action-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff; }
    .asset-card-container .card-overlay-action-menu ul {
      list-style: none;
      display: inline-flex; }
      .asset-card-container .card-overlay-action-menu ul li {
        cursor: pointer;
        padding: 5px 5px 5px 0; }
        .asset-card-container .card-overlay-action-menu ul li svg:hover {
          fill: #de3618; }
    .asset-card-container .card-overlay-action-menu .Polaris-Icon {
      border: none;
      padding: 0;
      top: auto; }
  .asset-card-container .card-overlay-action-menu-top-right {
    position: absolute;
    top: 0;
    right: 0; }
    .asset-card-container .card-overlay-action-menu-top-right img {
      width: 20px; }
    .asset-card-container .card-overlay-action-menu-top-right .content-item {
      background-color: #ffffff;
      padding: 7px 5px 2px 10px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 2px; }
  .asset-card-container .card-overlay-action-menu-top-left {
    position: absolute;
    top: 0;
    left: 0; }
    .asset-card-container .card-overlay-action-menu-top-left img {
      width: 20px; }
    .asset-card-container .card-overlay-action-menu-top-left .content-item {
      background-color: #FFA247;
      padding: 7px 10px 2px 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 2px; }
  .asset-card-container .card-overlay-action-menu-bottom {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .asset-card-container .card-overlay-action-menu-bottom img {
      width: 20px;
      height: 22px; }
      .asset-card-container .card-overlay-action-menu-bottom img.small {
        width: 15px; }
    .asset-card-container .card-overlay-action-menu-bottom .card-overlay-action-menu-bottom-content {
      display: flex;
      align-items: center; }
      .asset-card-container .card-overlay-action-menu-bottom .card-overlay-action-menu-bottom-content .content-item {
        margin: 0 7px; }
        .asset-card-container .card-overlay-action-menu-bottom .card-overlay-action-menu-bottom-content .content-item.checkbox {
          position: relative;
          width: 20px;
          height: 24px; }

.asset-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.asset-card-image-container {
  position: relative;
  height: 180px; }

.asset-card-image-container .asset-card-top-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
  color: #FFF; }
  .asset-card-image-container .asset-card-top-banner span {
    border: 2px solid white;
    padding: 8px; }

.gray-overlay, .asset-card-image-container:hover .asset-card-top-banner, .asset-card-image-container .asset-card-top-banner-selected {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: white;
  font-size: 20px;
  padding-top: 45%;
  animation: fadein 0.3s; }

.asset-card-image-container .asset-card-top-banner-selected {
  position: absolute; }
  .asset-card-image-container .asset-card-top-banner-selected span {
    border: 2px solid white;
    padding: 8px; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 0.8; } }

.asset-card-grid-container {
  display: grid;
  grid-template-columns: auto auto; }

.asset-card-grid-item-left {
  text-align: left; }
  .asset-card-grid-item-left .Polaris-Icon {
    margin-left: 0px; }

.asset-card-grid-item-right {
  text-align: right; }

.asset-card-grid-container-flex {
  margin-bottom: 20px; }
  .asset-card-grid-container-flex .asset-card-grid-container-flex-top {
    display: flex;
    margin-bottom: 5px; }
    .asset-card-grid-container-flex .asset-card-grid-container-flex-top .left {
      flex: 2; }
    .asset-card-grid-container-flex .asset-card-grid-container-flex-top .right {
      flex: 2; }

.card-overlay-container {
  /* padding: 8px; */
  margin: 16px;
  border: 2px solid white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 8px;
  padding-left: 8px;
  top: 45%;
  position: relative;
  -ms-transform: translateY(-55%);
  transform: translateY(-65%);
  display: inline-block;
  overflow-wrap: anywhere; }

.project-card-container {
  height: 200px;
  width: 200px;
  margin: 10px;
  vertical-align: middle; }
  .project-card-container:hover {
    transform: scale(1.03);
    transition-duration: 0.4s; }
    .project-card-container:hover .card-overlay-action-menu {
      display: block; }
  .project-card-container .card-overlay-action-menu {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    color: #fff; }
    .project-card-container .card-overlay-action-menu ul {
      list-style: none;
      display: inline-flex; }
      .project-card-container .card-overlay-action-menu ul li {
        cursor: pointer;
        padding: 5px 5px 5px 0; }
        .project-card-container .card-overlay-action-menu ul li svg:hover {
          fill: #de3618; }
    .project-card-container .card-overlay-action-menu .Polaris-Icon {
      border: none;
      padding: 0;
      top: auto; }

.asset-search-container {
  text-align: right;
  margin-right: 2rem;
  margin-left: 2rem; }

.asset-details-wrapper {
  max-width: 263px;
  flex: none; }
  .asset-details-wrapper .media-wrapper {
    height: auto;
    position: relative; }
    .asset-details-wrapper .media-wrapper .close-panel-container {
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 400; }
      .asset-details-wrapper .media-wrapper .close-panel-container img {
        width: 40px;
        height: 40px; }
    .asset-details-wrapper .media-wrapper .close-panel-container:hover {
      cursor: pointer; }

.panel-section .d-none {
  display: none; }

.panel-section .asset-tags-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center; }
  .panel-section .asset-tags-wrapper .title {
    font-weight: bold; }
  .panel-section .asset-tags-wrapper .asset-tag-item {
    background-color: #efefef; }
  .panel-section .asset-tags-wrapper .Polaris-Button:first-child {
    margin-right: 10px; }
  .panel-section .asset-tags-wrapper .plus-icon::before {
    content: ' ';
    background-image: url("./assets/icons/iconmonstr-plus-circle-lined 1.svg");
    background-size: 18px 18px;
    width: 15px;
    height: 15px;
    padding-left: 20px;
    background-repeat: no-repeat; }

.panel-section .asset-panel-legend {
  display: flex;
  justify-content: space-between;
  align-content: center; }
  .panel-section .asset-panel-legend .left-tag-legend {
    display: flex;
    align-items: center; }
    .panel-section .asset-panel-legend .left-tag-legend .legend-item {
      position: relative;
      padding-left: 20px; }
    .panel-section .asset-panel-legend .left-tag-legend .red {
      margin-left: 10px; }
    .panel-section .asset-panel-legend .left-tag-legend .legend-item:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      left: 2px;
      top: 3px; }
    .panel-section .asset-panel-legend .left-tag-legend .blue:before {
      background-color: #C2DFEF; }
    .panel-section .asset-panel-legend .left-tag-legend .red:before {
      background-color: #F9BFBF; }
  .panel-section .asset-panel-legend .chev-down-icon::before {
    content: ' ';
    background-image: url("./assets/icons/icon_red_arrow.svg");
    background-size: 18px 18px;
    width: 15px;
    height: 15px;
    padding-left: 20px;
    background-repeat: no-repeat; }

.panel-section .generic-btn {
  background-color: #efefef;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  border: none; }

.panel-section .module-row-item {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .panel-section .module-row-item .icon {
    width: 12px;
    margin-left: 10px;
    cursor: pointer; }
  .panel-section .module-row-item .jnto-clamp {
    cursor: pointer;
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    height: 39.2px;
    /* Fallback for non-webkit */
    font-size: 14px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }

.asset-details-descriptions-container {
  margin: 20px 0 20px 0; }

.asset-details-tags-container {
  margin: 20px 0 20px 0; }

.asset-details-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px 5px 5px 5px; }

.asset-details-item-left {
  width: 45%;
  padding-right: 5px;
  white-space: initial; }

.asset-details-item-left-red {
  color: red; }

.asset-details-item-right {
  padding-left: 5px;
  flex: 1; }

.asset-details-item-right-red {
  color: red; }

.asset-details-tag-form-container > div {
  text-align: right;
  margin-bottom: 5px; }

.asset-details-tag-form-container button::after {
  content: " ";
  clear: both; }

.asset-details-tag-form-container form {
  margin: 10px 0; }

.asset-details-add-tag {
  float: right;
  text-transform: uppercase; }
  .asset-details-add-tag.ml-4 {
    margin-left: 1em; }

.badge-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px; }
  .badge-section .asset-detail-badge-text {
    position: relative;
    padding-left: 20px; }
    .badge-section .asset-detail-badge-text:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      left: 2px;
      top: 3px; }
  .badge-section .Polaris-Badge {
    padding: 4px 9px; }
  .badge-section .Polaris-Badge--statusInfo {
    background-color: #EFEFEF; }
    .badge-section .Polaris-Badge--statusInfo .asset-detail-badge-text:before {
      background-color: #C2DFEF; }
  .badge-section .Polaris-Badge--statusWarning {
    background-color: #EFEFEF; }
    .badge-section .Polaris-Badge--statusWarning .asset-detail-badge-text:before {
      background-color: #F9BFBF; }

.asset-detail-badge-container {
  display: flex;
  align-items: center;
  padding: 2px;
  position: relative; }

.delete-tag-icon {
  float: right;
  font-size: 1.3rem;
  line-height: 2rem;
  padding-left: 5px;
  color: #3e4e57;
  cursor: pointer;
  font-weight: 700; }
  .delete-tag-icon.floating {
    position: absolute;
    top: -7px;
    right: -16px;
    height: 21px;
    width: 21px;
    padding: 3px 3px 0 3px;
    color: white;
    text-align: center;
    line-height: 3px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .delete-tag-icon.floating {
        padding: 0 3px 0 3px; } }
  .delete-tag-icon .asset-icon-bg {
    background-color: white;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 8px;
    right: 8px; }
  .delete-tag-icon.inline {
    top: -8px;
    right: -20px;
    height: 15px;
    width: 15px;
    background-color: #4e4e4e;
    padding: 5px 3px;
    border-radius: 4px;
    color: white;
    border: 1px solid white;
    font: 1.3rem Helvetica, Verdana, Tahoma;
    text-align: center;
    line-height: 3px;
    margin-left: 5px; }

.asset-detail-row {
  display: flex; }
  .asset-detail-row .asset-detail-col {
    text-align: left; }
    .asset-detail-row .asset-detail-col.left {
      width: 50%;
      padding-right: 10px;
      overflow: scroll; }
    .asset-detail-row .asset-detail-col.right {
      flex-grow: 1;
      flex-basis: 0%;
      padding-left: 10px;
      padding-right: 10px;
      flex: 1;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden; }
      .asset-detail-row .asset-detail-col.right::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px; }
      .asset-detail-row .asset-detail-col.right::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
    .asset-detail-row .asset-detail-col .media-wrapper {
      width: 90%;
      margin: 0 auto; }
      .asset-detail-row .asset-detail-col .media-wrapper .asset-details-image {
        height: auto;
        width: auto;
        max-height: 500px;
        object-fit: contain;
        background-color: #ffffff; }
    .asset-detail-row .asset-detail-col .panel-section .panel-two-col {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .asset-detail-row .asset-detail-col .panel-divider {
      border-top: 2px solid #ccc;
      margin: 10px 0; }

@media (min-width: 1266px) {
  .asset-details-wrapper {
    max-width: 300px; } }

@media (min-width: 1400px) {
  .asset-details-wrapper {
    max-width: 419px; } }

.asset-detail-buttons {
  width: 100%;
  display: flex;
  justify-content: center; }

.asset-detail-buttons > div {
  padding-left: 10px;
  padding-right: 10px; }

.asset-search-results-container {
  margin: 0 20px 0 20px; }

.search-results-placeholder {
  min-height: 300px;
  padding-top: 100px;
  text-align: center; }

.blog-post-list-container {
  min-height: 400px;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto; }

.header, .footer {
  height: 60px;
  display: table;
  border-collapse: collapse;
  width: 100%; }

.grid-container {
  display: table-cell;
  vertical-align: middle;
  padding: 0 40px 0 40px;
  height: 30px; }

.header img, .footer img {
  height: 30px;
  width: 60px;
  vertical-align: middle; }

.selector-container {
  width: 157px;
  text-align: left;
  margin: 0 auto;
  float: right;
  height: 30px; }

@media (max-width: 740px) {
  .header, .footer {
    height: 44px; }
  .header img, .footer img {
    height: 25px;
    width: 53px;
    vertical-align: middle; }
  .grid-container {
    padding: 0 20px 0 20px; } }

.footer {
  background-color: black;
  color: gray;
  bottom: 0;
  position: fixed; }

.footer-jnto-logo {
  height: 30px;
  width: 65px; }

.footer-copy-right {
  text-align: right;
  font-size: 10px;
  height: 30px;
  line-height: 30px; }

@media (max-width: 740px) {
  .footer {
    padding: 20px 10px 20px 10px;
    height: 120px; }
  .footer .MuiGrid-spacing-xs-24-24 > .MuiGrid-item-2 {
    padding-top: 0;
    padding-bottom: 0; }
  .footer-jnto-logo {
    height: 30px;
    width: 50px;
    margin-left: auto;
    margin-right: auto; }
  .footer-copy-right {
    text-align: center; } }

.asset-details-info-container, .asset-details-keywords-container {
  width: 300px;
  margin-bottom: 15px; }

.asset-details-info-table {
  margin-left: 20px; }

.asset-details-image {
  line-height: 200px;
  text-align: center;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: auto; }

.asset-details-keywords-container {
  width: 500px; }

.asset-details-keywords {
  margin-left: 20px; }

.asset-details-footer-container {
  width: 350px; }

.signup {
  width: 350px;
  height: 350px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 10px;
  border-color: #3f51b5;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px; }

.signup-form, .signin-form {
  text-align: center; }

.signup-signin-parent-container, .signin-signup-parent-container {
  width: 100%;
  text-align: right;
  margin-bottom: 20px; }

.signup-signin-container, .signin-signup-container {
  right: 0;
  width: 260px;
  float: right; }

.signin-signup-parent-container {
  text-align: right; }

.signin-signup-container {
  text-align: right;
  width: 325px; }

.user-authentication-container {
  width: 400px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.user-authentication-form.set-bg .Polaris-TextField__Input, .generic-form-container.set-bg .Polaris-TextField__Input {
  background: #f3f3f3; }

.user-authentication-form .form-header, .generic-form-container .form-header {
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: bold; }

.user-authentication-form .field-item-content, .generic-form-container .field-item-content {
  margin-bottom: 30px; }
  .user-authentication-form .field-item-content input, .generic-form-container .field-item-content input {
    border-radius: 5px;
    padding: 10px; }
    .user-authentication-form .field-item-content input:focus, .generic-form-container .field-item-content input:focus {
      outline: none; }
  .user-authentication-form .field-item-content .Polaris-TextField, .generic-form-container .field-item-content .Polaris-TextField {
    border-radius: 10px; }
    .user-authentication-form .field-item-content .Polaris-TextField .Polaris-TextField__Input, .generic-form-container .field-item-content .Polaris-TextField .Polaris-TextField__Input {
      border: none; }
  .user-authentication-form .field-item-content .Polaris-TextField__Backdrop, .generic-form-container .field-item-content .Polaris-TextField__Backdrop {
    border-radius: 5px;
    border: none; }
  .user-authentication-form .field-item-content .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after, .generic-form-container .field-item-content .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after {
    border: none; }
  .user-authentication-form .field-item-content .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop, .generic-form-container .field-item-content .Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop {
    border: none; }

.user-authentication-form .submit-container, .generic-form-container .submit-container {
  text-align: center;
  margin: 25px 0; }
  .user-authentication-form .submit-container button, .generic-form-container .submit-container button {
    cursor: pointer;
    background: #e7001d;
    outline: none;
    border-radius: 100px;
    padding: 10px 60px;
    color: white; }
    .user-authentication-form .submit-container button.Polaris-Button--disabled, .generic-form-container .submit-container button.Polaris-Button--disabled {
      background: gray; }
    .user-authentication-form .submit-container button:hover, .generic-form-container .submit-container button:hover {
      color: white;
      background: #d6041f; }

.user-authentication-form .form-link-container, .generic-form-container .form-link-container {
  text-align: center; }
  .user-authentication-form .form-link-container button, .generic-form-container .form-link-container button {
    position: relative;
    color: #292c2f; }
    .user-authentication-form .form-link-container button:after, .generic-form-container .form-link-container button:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -3px;
      border-width: 0 0 2px;
      border-style: solid;
      border-color: #9d9d9d; }

.user-authentication-form input:-webkit-autofill,
.user-authentication-form input:-webkit-autofill:hover,
.user-authentication-form input:-webkit-autofill:focus,
.user-authentication-form textarea:-webkit-autofill,
.user-authentication-form textarea:-webkit-autofill:hover,
.user-authentication-form textarea:-webkit-autofill:focus,
.user-authentication-form select:-webkit-autofill,
.user-authentication-form select:-webkit-autofill:hover,
.user-authentication-form select:-webkit-autofill:focus, .generic-form-container input:-webkit-autofill,
.generic-form-container input:-webkit-autofill:hover,
.generic-form-container input:-webkit-autofill:focus,
.generic-form-container textarea:-webkit-autofill,
.generic-form-container textarea:-webkit-autofill:hover,
.generic-form-container textarea:-webkit-autofill:focus,
.generic-form-container select:-webkit-autofill,
.generic-form-container select:-webkit-autofill:hover,
.generic-form-container select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #292c2f;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s; }

.project-card {
  height: 200px;
  width: 100%;
  display: table-cell;
  vertical-align: middle; }

.folders-text {
  display: flex;
  justify-content: center; }

.folders-container {
  margin: -15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center; }

.folders-banner-action-group button {
  margin-right: 20px; }

.card-overlay {
  display: block;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: white;
  font-size: 20px;
  animation: fadein 0.3s;
  position: absolute; }

.generic-card-container {
  height: 200px;
  width: 200px;
  margin: 10px;
  vertical-align: middle; }
  .generic-card-container:hover {
    transform: scale(1.03);
    transition-duration: 0.4s; }
    .generic-card-container:hover .card-overlay-action-menu {
      display: block; }
  .generic-card-container .card-overlay-action-menu {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    color: #fff; }
    .generic-card-container .card-overlay-action-menu ul {
      list-style: none;
      display: inline-flex; }
      .generic-card-container .card-overlay-action-menu ul li {
        cursor: pointer;
        padding: 5px 5px 5px 0; }
        .generic-card-container .card-overlay-action-menu ul li svg:hover {
          fill: #de3618; }
    .generic-card-container .card-overlay-action-menu .Polaris-Icon {
      border: none;
      padding: 0;
      top: auto; }

.generic-card-container-v2, .generic-card-placeholder {
  width: 450px;
  margin: 15px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap; }
  .generic-card-container-v2 .generic-card-container-image, .generic-card-placeholder .generic-card-container-image {
    cursor: pointer;
    width: 150px;
    height: 150px; }
    .generic-card-container-v2 .generic-card-container-image img, .generic-card-placeholder .generic-card-container-image img {
      width: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
  .generic-card-container-v2 .generic-card-container-content, .generic-card-placeholder .generic-card-container-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 300px; }
    .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-name, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-name {
      cursor: pointer;
      flex: 1;
      font-weight: bold; }
      .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-name:hover, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-name:hover {
        color: #e7001d; }
    .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-bottom, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-bottom {
      display: flex;
      justify-content: space-between; }
      .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions {
        align-items: center;
        display: flex; }
        .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions img, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions img {
          cursor: pointer;
          margin-left: 15px;
          width: 20px; }
          .generic-card-container-v2 .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions img:hover, .generic-card-placeholder .generic-card-container-content .generic-card-container-content-bottom .generic-card-container-content-bottom-actions img:hover {
            filter: invert(34%) sepia(100%) saturate(4741%) hue-rotate(355deg) brightness(91%) contrast(90%); }

.generic-card-placeholder {
  height: 0px; }

.user-profile-form-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.user-profile-localization-container {
  margin: 15px;
  padding-top: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.user-metadata div {
  margin-right: 50px; }
  .user-metadata div span {
    padding-right: 2px; }

.react-grid-Grid {
  min-height: calc(100vh - 56px) !important; }

.highlight-cell {
  background-color: palevioletred !important; }

.grid-errors-list {
  column-count: 3;
  list-style-position: inside;
  margin: 25px 30px; }

.react-grid-image {
  transform: translateY(5%) !important;
  background-position: center !important;
  background-repeat-y: no-repeat !important; }

.Polaris-DataTable__Table tr:first-child {
  background-color: white; }

.Polaris-DataTable__Table td {
  border-left: none;
  border-right: none;
  line-height: 20px;
  padding-bottom: 5px; }

.Polaris-DataTable__Table th {
  border-left: none;
  border-right: none;
  line-height: 20px; }

.Polaris-DataTable__Table {
  background-color: white !important; }

.rc-steps-item-icon {
  background: #5563c1 !important;
  border-color: #5563c1 !important; }

.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #5563c1 !important; }

.rc-steps-item-finish .rc-steps-item-icon {
  background: transparent !important; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .Polaris-Frame-ToastManager .Polaris-Frame-ToastManager__ToastWrapper {
    position: relative; }
  input[type=search]::-ms-clear {
    display: none; }
  .react-grid-HeaderCell.react-grid-HeaderCell--frozen {
    padding: 0 8px; }
    .react-grid-HeaderCell.react-grid-HeaderCell--frozen .react-grid-checkbox-container > .react-grid-checkbox-label {
      top: 0;
      transform: translateY(16%); } }

.page-loader {
  display: none; }
  .page-loader.loader-isvisible {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    .page-loader.loader-isvisible:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3); }
  .page-loader .page-loader-content {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .page-loader .page-loader-content .spinner-context-text {
      font-size: 2.5em;
      color: #fff;
      padding-left: 8px;
      z-index: 1; }
    .page-loader .page-loader-content .jnto-dam-loader-dot {
      animation: 1s blink infinite; }
      .page-loader .page-loader-content .jnto-dam-loader-dot:nth-child(2) {
        animation-delay: 250ms; }
      .page-loader .page-loader-content .jnto-dam-loader-dot:nth-child(3) {
        animation-delay: 500ms; }

@keyframes blink {
  50% {
    color: transparent;
    opacity: 1; } }

.grid-detail-modal-window {
  position: fixed;
  background-color: rgba(33, 43, 54, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s; }
  .grid-detail-modal-window h3 {
    font-size: 1.5em;
    font-weight: 500; }
  .grid-detail-modal-window.show {
    opacity: 1;
    pointer-events: auto; }
  .grid-detail-modal-window > div {
    width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 6px; }
    .grid-detail-modal-window > div img {
      width: 100%;
      max-height: 450px;
      background-color: lightgray; }
  .grid-detail-modal-window .grid-modal-header {
    padding: 1.6rem;
    border-bottom: 0.1rem solid #dfe3e8; }
  .grid-detail-modal-window .grid-modal-footer {
    padding: 1.6rem;
    border-top: 0.1rem solid #dfe3e8; }
  .grid-detail-modal-window .grid-modal-body {
    display: flex;
    padding: 1.6rem 2rem; }
    .grid-detail-modal-window .grid-modal-body > * {
      width: 50%; }
    .grid-detail-modal-window .grid-modal-body .asset-metadata {
      margin-left: 20px;
      overflow-y: scroll;
      height: 450px; }
      .grid-detail-modal-window .grid-modal-body .asset-metadata dt {
        font-size: 1.125em;
        padding: 4px 0; }
        .grid-detail-modal-window .grid-modal-body .asset-metadata dt span {
          margin-right: 5px; }
    .grid-detail-modal-window .grid-modal-body ::-webkit-scrollbar {
      width: 10px; }
    .grid-detail-modal-window .grid-modal-body ::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .grid-detail-modal-window .grid-modal-body ::-webkit-scrollbar-thumb {
      background: #888; }
    .grid-detail-modal-window .grid-modal-body ::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .grid-detail-modal-window .close-modal {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer; }
  .grid-detail-modal-window .metadata-header {
    font-weight: bold; }
  .grid-detail-modal-window .button-actions-container {
    display: flex;
    justify-content: space-between; }
    .grid-detail-modal-window .button-actions-container button {
      outline: none;
      border: none;
      padding: 10px;
      font-size: 1em;
      cursor: pointer; }
  .grid-detail-modal-window header {
    font-weight: bold; }
  .grid-detail-modal-window h1 {
    font-size: 150%;
    margin: 0 0 15px; }

.loading-spinner {
  margin-top: 5%;
  text-align: center; }

/**
 To have separate scrollable containers at full height
 */
#root {
  height: 100%; }
  #root > :first-child {
    height: 100%; }

.Polaris-Frame,
.Polaris-Frame__Main,
.Polaris-Frame__Content,
.app {
  height: 100%; }

.app {
  overflow: auto; }

.Polaris-Page {
  overflow: auto;
  height: 100%;
  padding-bottom: 100px; }

.Polaris-Page.Polaris-Page--fullWidth {
  padding: 0; }

.Polaris-Card__Header,
.Polaris-Card__Section {
  word-break: break-all; }

.Polaris-Card__Section {
  min-height: 120px; }

.Polaris-Card__SectionHeader {
  min-height: 50px; }

.Polaris-Badge {
  border-radius: 5px !important; }

/*
  End Layout Overrides
 */
.main-nav {
  margin-top: -55px;
  position: absolute;
  width: 100%;
  height: 55px;
  display: flex;
  z-index: 999;
  background-color: black;
  color: white;
  padding: 0 25px;
  align-items: center; }
  .main-nav.flex {
    display: flex; }
  .main-nav .flex {
    display: flex; }
  .main-nav .nav-logo {
    cursor: pointer; }
    .main-nav .nav-logo img {
      width: 50px; }
  .main-nav .logo-container {
    padding-top: 10px;
    align-items: flex-start; }
  .main-nav .nav-title {
    font-size: 25px;
    font-weight: bold;
    margin-left: 25px; }
  .main-nav .nav-items {
    justify-content: flex-end;
    align-self: self-end;
    flex: 1; }
    .main-nav .nav-items ul {
      display: flex;
      list-style: none; }
      .main-nav .nav-items ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer; }
        .main-nav .nav-items ul li a {
          font-size: 14px;
          font-weight: bold;
          color: white;
          text-decoration: none;
          margin-left: 25px;
          padding: 15px 0;
          border-bottom-style: inset;
          border-bottom: solid 5px transparent; }
          .main-nav .nav-items ul li a:hover {
            color: #ffffff;
            border-bottom: solid 5px #e7001d; }
          .main-nav .nav-items ul li a.active {
            border-bottom: solid 5px #e7001d; }
          .main-nav .nav-items ul li a.no-hover {
            border-bottom: none; }
        .main-nav .nav-items ul li svg {
          fill: white; }
        .main-nav .nav-items ul li .dropdown {
          width: 330px;
          z-index: 100;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          transition: all 0.5s ease;
          margin-top: 7px;
          right: -10px;
          display: none;
          padding: 10px;
          background-color: #ffffff;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px; }
          .main-nav .nav-items ul li .dropdown input {
            border: none;
            padding: 10px; }
            .main-nav .nav-items ul li .dropdown input:focus {
              outline: none; }
        .main-nav .nav-items ul li:hover .dropdown {
          visibility: visible;
          opacity: 1;
          display: block; }
        .main-nav .nav-items ul li.nav-icon-wrapper a {
          padding-top: 0;
          padding-bottom: 0; }
        .main-nav .nav-items ul li.active-notification ::after {
          content: "";
          position: absolute;
          z-index: 1;
          top: 12px;
          right: -7px;
          width: .8rem;
          height: .8rem;
          border-radius: 100%;
          background-color: #47c1bf; }
        .main-nav .nav-items ul li.nav-icon-wrapper.active-notification ::after {
          top: 6px;
          right: -3px; }
  .main-nav .nav-icon {
    width: 35px;
    height: 35px; }
  .main-nav .nav-icon-red {
    display: none; }
  .main-nav .nav-icon-wrapper:hover .nav-icon-red {
    display: flex;
    margin-bottom: 5px; }
  .main-nav .nav-icon-wrapper:hover .nav-icon-black {
    display: none;
    height: 0px; }
  .main-nav .nav-search-container {
    display: flex; }
    .main-nav .nav-search-container input {
      flex: 1; }

.search-container-block {
  margin-right: 100px; }

.main-nav-external {
  margin-top: 0px; }

.app-container {
  padding-top: 55px;
  height: 100%;
  display: flex; }
  .app-container .app {
    position: relative;
    flex: 1; }
    .app-container .app .Polaris-Frame {
      min-height: calc(100vh - 58px); }

.floating-nav-bar {
  z-index: 500;
  width: 96%;
  padding: 15px;
  margin: 15px auto 15px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  background-color: #292c2f; }
  .floating-nav-bar .floating-nav-bar-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .floating-nav-bar .manual-floating-nav-bar-content {
    min-height: 50px;
    display: flex;
    justify-content: space-between; }
  .floating-nav-bar .floating-bar-btn-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 0; }
    .floating-nav-bar .floating-bar-btn-container.right {
      flex-direction: row-reverse; }
    .floating-nav-bar .floating-bar-btn-container .floating-bar-btn-container-img-item {
      margin: 0 10px; }
      .floating-nav-bar .floating-bar-btn-container .floating-bar-btn-container-img-item img {
        width: 25px;
        height: 25px; }
    .floating-nav-bar .floating-bar-btn-container.right .floating-bar-btn-container-img-item img {
      transform: rotate(180deg); }
    .floating-nav-bar .floating-bar-btn-container.left .floating-bar-btn-container-img-item img {
      transform: rotate(0deg); }
    .floating-nav-bar .floating-bar-btn-container .floating-bar-btn-container-content-item {
      color: white;
      max-width: 164px;
      line-height: 22px;
      font-weight: bold; }
      .floating-nav-bar .floating-bar-btn-container .floating-bar-btn-container-content-item:hover {
        color: #e7001d; }
  .floating-nav-bar a {
    text-decoration: none; }

.dui-btn {
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  margin: 10px 15px;
  min-width: 117px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  .dui-btn.small {
    padding: 5px 15px;
    margin: 5px 7px; }
  .dui-btn.slim {
    padding: 10px 20px;
    margin: 7px 11px; }
  .dui-btn.skinny {
    padding: 10px 30px; }
  .dui-btn.xs-skinny {
    padding: 7px 30px;
    min-height: 20px;
    min-width: 87px; }
  .dui-btn.slim-radius {
    border-radius: 10px; }

.dui-btn {
  text-decoration: none;
  min-height: 40px; }
  .dui-btn.tooltip {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 0; }
    .dui-btn.tooltip img {
      width: 20px;
      height: 20px;
      margin-right: 5px; }
    .dui-btn.tooltip .dam-ui-hint {
      position: relative; }
      .dui-btn.tooltip .dam-ui-hint .dam-ui-tooltip-content {
        visibility: hidden;
        position: absolute;
        z-index: 1;
        padding: 25px;
        border-radius: 6px;
        background-color: white;
        color: #292c2f;
        min-width: 350px;
        line-height: 26px;
        left: -13px;
        text-align: left;
        bottom: 25px;
        font-size: 14px; }
      .dui-btn.tooltip .dam-ui-hint:hover .dam-ui-tooltip-content {
        visibility: visible; }

.dui-btn-primary {
  background-color: #e7001d;
  color: #ffffff; }
  .dui-btn-primary:hover {
    background-color: #d6041f; }

.dui-btn-primary:disabled {
  background-color: #e7001d;
  opacity: .6; }

.dui-btn-secondary {
  background-color: #d9d8d8;
  color: #292c2f; }
  .dui-btn-secondary:hover {
    background-color: #D9D8D8; }

.dui-btn-secondary:disabled {
  opacity: .6; }

.dui-btn-secondary-alt {
  background-color: #efefef;
  color: #292c2f; }
  .dui-btn-secondary-alt:hover {
    background-color: #dedede; }
  .dui-btn-secondary-alt.red--text {
    color: #e7001d; }
  .dui-btn-secondary-alt.white--text {
    color: white; }
  .dui-btn-secondary-alt.bg-red {
    background-color: #e7001d; }
  .dui-btn-secondary-alt.no-mrgn {
    margin: 0; }
  .dui-btn-secondary-alt:disabled {
    background-color: #efefef;
    opacity: .4; }

.jnto-filter-bar-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 25px; }
  .jnto-filter-bar-wrapper .search-input-container {
    border-radius: 50px;
    background-color: #ffffff;
    display: flex; }
    .jnto-filter-bar-wrapper .search-input-container input {
      margin-left: 20px;
      padding: 7px;
      border: none;
      outline: none;
      width: 100%;
      margin-top: 3px; }
  .jnto-filter-bar-wrapper .filter-options-block {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .jnto-filter-bar-wrapper .filter-options-block select {
      min-width: 175px; }
    .jnto-filter-bar-wrapper .filter-options-block .field-group .jnto-selector-container::before {
      width: 14%; }
    .jnto-filter-bar-wrapper .filter-options-block .radio-group {
      margin-left: 15px; }

.secondary-option-block {
  padding: 10px 25px; }

.tooltip-filter-bar {
  padding-left: 20px;
  display: flex;
  margin-top: 5px; }

.Polaris-Tooltip {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 10px !important; }

.tooltip-icon {
  height: 27px;
  padding-right: 5px;
  margin-top: -3px; }

.Polaris-Tooltip__Wrapper {
  background-color: #EFEFEF !important;
  color: #000000 !important; }

.Polaris-Tooltip__Label {
  padding: 10px 10px 10px 10px !important; }

.tooltip-mouseover {
  color: #888888;
  text-decoration: underline; }

.jnto-filter-bar-collapsible-wrapper {
  z-index: 9995;
  background-color: white;
  padding: 10px 25px; }
  .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-top-row .chev-down-btn {
      background-color: white;
      margin: 0 0; }
      .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-top-row .chev-down-btn.chev-up span::before {
        transform: rotate(180deg); }
  .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .Polaris-Icon {
      border-radius: 5px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .jnto-filter-bar-collapsible-popover {
      padding-right: 30px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .Polaris-TextField__Input {
      height: 40px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item {
      margin-right: 30px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-label {
      margin-bottom: 6px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-field {
      margin-right: 10px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-field-checkbox {
      background-color: #EFEFEF;
      border-radius: 8px;
      padding: 5px 15px 5px 10px;
      height: 40px;
      display: block; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-field-text {
      position: relative;
      z-index: 999; }
      .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-field-text input {
        background-color: #EFEFEF;
        padding: 0px 10px;
        min-height: 3.1rem;
        color: #5c636a; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-fields .field-group .jnto-selector-container {
      margin-right: 30px; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-fields .field-group select {
      padding: 8px;
      width: 100px;
      background-color: #efefef; }
    .jnto-filter-bar-collapsible-wrapper .jnto-filter-bar-collapsible-row .filter-item-fields .field-group .jnto-selector-container::before {
      background-size: 15px 15px;
      right: -18px; }

label.Polaris-Choice {
  margin-bottom: 15px; }

.Polaris-Popover__Pane .Polaris-OptionList__Options {
  background-color: #EFEFEF; }

.Polaris-Popover__Wrapper {
  margin-left: -32px;
  width: 260px;
  border-radius: 12px !important;
  border: none !important;
  background-color: #EFEFEF; }

.Polaris-Popover {
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px !important; }

.Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:focus + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox:not(.Polaris-OptionList-Checkbox--newDesignLanguage) .Polaris-OptionList-Checkbox__Input:active + .Polaris-OptionList-Checkbox__Backdrop {
  background: transparent !important;
  box-shadow: 0 0 0 0px; }

.main-sidebar {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 280px;
  flex: 1;
  background-color: #292c2f;
  color: white;
  padding: 40px 0; }
  .main-sidebar .sidebar-wrapper {
    padding: 0 25px; }
  .main-sidebar a {
    color: white; }
  .main-sidebar section {
    color: #bfc0c1;
    margin: 25px 0; }
  .main-sidebar .sidebar-main-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 40px;
    padding: 0 25px; }
  .main-sidebar .sidebar-sub-title {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 5px; }
  .main-sidebar .sidebar-text {
    font-size: 16px; }
  .main-sidebar .top-content {
    flex: 1; }
  .main-sidebar .box-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    color: #292c2f;
    cursor: pointer; }
    .main-sidebar .box-item .box-item-icon .icon {
      width: 25px; }
    .main-sidebar .box-item .box-item-content {
      padding-left: 15px; }
  .main-sidebar .list-view {
    margin-bottom: 25px; }
    .main-sidebar .list-view ol {
      list-style-type: none;
      font-size: 16px;
      font-weight: bold; }
      .main-sidebar .list-view ol a {
        text-decoration: none; }
      .main-sidebar .list-view ol li:after {
        content: url("https://images.ctfassets.net/2uxxifu5nzlv/4QxqdHjhBMgTTx6cW9rZXf/8a4f32b2ea94cfac10cdafdb39932944/icon_tick_down_black.svg");
        position: absolute;
        left: 19px;
        transform: rotate(-90deg);
        width: 12px;
        height: 12px;
        filter: invert(1);
        top: 24px; }
      .main-sidebar .list-view ol li {
        position: relative; }
      .main-sidebar .list-view ol li.no-arrow::after {
        display: none; }
      .main-sidebar .list-view ol li.active::before {
        content: "";
        position: absolute;
        background-color: #555555;
        width: 100%;
        height: 100%; }
      .main-sidebar .list-view ol li.active .ordered-sub-list {
        visibility: visible;
        max-height: 500px;
        transition: max-height 0.25s ease-in; }
      .main-sidebar .list-view ol li.active::after {
        transform: rotate(0deg);
        top: 17px; }
      .main-sidebar .list-view ol li div {
        padding: 20px 40px;
        padding-left: 60px; }
      .main-sidebar .list-view ol ol li {
        padding: 10px 0; }
        .main-sidebar .list-view ol ol li div {
          padding: 0; }
    .main-sidebar .list-view .sub-heading {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold; }
    .main-sidebar .list-view .ordered-list {
      counter-reset: list-counter; }
    .main-sidebar .list-view .ordered-sub-list {
      transition: max-height 0.25s ease-out;
      visibility: hidden;
      max-height: 0;
      counter-reset: sublist-counter; }
      .main-sidebar .list-view .ordered-sub-list li:last-child {
        padding-bottom: 20px; }
    .main-sidebar .list-view .ordered-list > li > div,
    .main-sidebar .list-view .ordered-sub-list > li > div {
      position: relative; }
    .main-sidebar .list-view .ordered-list > li > div {
      counter-increment: list-counter; }
    .main-sidebar .list-view .ordered-list > li > div:hover {
      cursor: pointer; }
    .main-sidebar .list-view .ordered-list-no-click > li > div:hover {
      cursor: auto; }
    .main-sidebar .list-view .ordered-sub-list > li > div {
      counter-increment: sublist-counter;
      font-size: 14px; }
    .main-sidebar .list-view .ordered-sub-list > li > div:hover {
      cursor: pointer; }
    .main-sidebar .list-view .ordered-sub-list li > div:first-child {
      margin-top: 0;
      padding-left: 50px; }
    .main-sidebar .list-view .ordered-sub-list li:after {
      left: 23px;
      width: 9px;
      height: 9px;
      top: 15px; }
    .main-sidebar .list-view .ordered-list > li > div::before,
    .main-sidebar .list-view .ordered-sub-list > li > div::before {
      position: absolute;
      top: 0;
      width: 30px;
      text-align: right;
      color: #ffffff;
      left: 25px;
      padding-top: 20px; }
    .main-sidebar .list-view .ordered-list > li > div::before {
      content: counter(list-counter) "."; }
    .main-sidebar .list-view .ordered-sub-list li.active:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 16px;
      right: 0;
      width: .8rem;
      height: .8rem;
      border-radius: 100%;
      background-color: #e7001d; }
    .main-sidebar .list-view .ordered-sub-list li:after {
      content: "";
      filter: invert(0); }

.jnto-generic-page-wrapper {
  margin-bottom: 70px;
  padding: 30px 50px; }
  .jnto-generic-page-wrapper section {
    margin-bottom: 25px; }
    .jnto-generic-page-wrapper section.secondary-block a {
      color: inherit;
      text-decoration: none;
      position: relative; }
      .jnto-generic-page-wrapper section.secondary-block a:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -3px;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #9d9d9d; }
    .jnto-generic-page-wrapper section.secondary-block p {
      line-height: 24px; }
    .jnto-generic-page-wrapper section.secondary-block .d-flex {
      display: flex; }
    .jnto-generic-page-wrapper section.secondary-block .block-item {
      margin-right: 30px; }

.generic-block h3 {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 5px; }

.generic-block h2 {
  color: #161616;
  font-size: 20px;
  font-weight: bold; }

.generic-block p {
  color: #1d1d1d;
  font-size: 14px;
  line-height: 24px; }

.simple-divider {
  border-top: 1px solid #4f5459;
  margin: 26px 0; }

.jnto-dropzone-wrapper {
  margin-top: 50px; }
  .jnto-dropzone-wrapper .Polaris-DropZone-FileUpload {
    background-color: #ffffff; }
  .jnto-dropzone-wrapper .Polaris-DropZone,
  .jnto-dropzone-wrapper .Polaris-DropZone-FileUpload {
    border-radius: 7px; }
  .jnto-dropzone-wrapper .Polaris-DropZone--hasOutline {
    border: none; }
  .jnto-dropzone-wrapper .Polaris-Button {
    background: unset;
    border-radius: 50px;
    color: #e7001d;
    background-color: #efefef;
    outline: none;
    border: none;
    padding: 10px 30px; }

.text-red {
  color: #e7001d; }

.upload-vendor-select select {
  background-color: #efefef;
  padding: 15px;
  width: 100%;
  border-radius: 7px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 220px; }

.upload-vendor-select {
  position: relative;
  margin-top: 15px; }

.upload-vendor-select::before {
  background-image: url("https://images.ctfassets.net/2uxxifu5nzlv/4QxqdHjhBMgTTx6cW9rZXf/8a4f32b2ea94cfac10cdafdb39932944/icon_tick_down_black.svg");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  display: inline-block;
  content: "";
  position: absolute;
  top: 0px;
  left: 200px;
  width: 3%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #d7d7d7;
  pointer-events: none;
  padding: 0 10px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background-position: center; }

.upload-vendor-select:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: #c8c7c7; }

.Polaris-Button.Polaris-Button--primary {
  color: red;
  background: #efefef;
  border: none;
  box-shadow: none;
  border-radius: 7px; }
  .Polaris-Button.Polaris-Button--primary:hover {
    color: red;
    background: #cac9c9; }

.Polaris-Button.Polaris-Button--sizeSlim {
  border-radius: 7px;
  border: none;
  box-shadow: none;
  color: red;
  background: #efefef; }

.bullet-list h2 {
  font-weight: bold;
  margin-bottom: 10px; }

.bullet-list ul {
  margin-left: 17px; }

.hide {
  display: none !important; }

.d-flex {
  display: flex; }

.Polaris-TextField {
  border-radius: 10px; }
  .Polaris-TextField .Polaris-TextField__Input {
    border: none; }
  .Polaris-TextField input {
    border-radius: 5px;
    padding: 10px; }
    .Polaris-TextField input:focus {
      outline: none; }

.Polaris-TextField__Backdrop::after {
  border: transparent !important; }

.Polaris-Label {
  margin-bottom: 10px; }

.Polaris-TextField__Backdrop {
  border-radius: 7px !important;
  border: none !important; }

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop::after {
  border: none; }

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop {
  border: none; }

.field-item-content {
  margin-bottom: 15px; }

.dam-ui-par {
  margin: 10px 0;
  cursor: pointer; }

.dam-ui-link {
  color: #292c2f;
  text-decoration: none;
  position: relative; }
  .dam-ui-link::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: #9d9d9d; }
  .dam-ui-link:hover {
    color: #e7001d; }

.user-page {
  text-decoration: none;
  position: relative;
  background-color: #e7001d;
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer; }

.Polaris-ButtonGroup {
  background-color: white;
  padding: 5px;
  border-radius: 5px; }
  .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary {
    background-color: #e7001d;
    color: white;
    border-radius: 5px; }
  .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    border: none;
    box-shadow: none;
    background-color: white; }

.basic-split-form {
  padding: 0 2px; }
  .basic-split-form .left-col {
    flex: 1;
    padding-right: 15px; }
  .basic-split-form .right-col {
    flex: 1;
    padding-left: 15px; }

.field-item-content.button-only {
  margin-top: 25px; }

.field-item-content .Polaris-Button {
  border: none;
  background: #e7001d !important;
  box-shadow: none;
  color: white;
  border-radius: 5px; }

.standard-spacing {
  margin-bottom: 15px; }

.pt-0 {
  padding-top: 0 !important; }

.incoming-requests-grid div {
  white-space: inherit; }

.outgoing-requests-grid div {
  white-space: inherit; }

.incomplete-uploads-grid div {
  white-space: inherit; }

.file-upload-grid-container div {
  white-space: inherit; }

.checkInputDisable {
  opacity: .4; }

.react-grid-Grid .react-grid-Viewport .react-grid-Canvas::-webkit-scrollbar {
  -webkit-appearance: none; }

.react-grid-Grid .react-grid-Viewport .react-grid-Canvas::-webkit-scrollbar:vertical {
  width: 11px; }

.react-grid-Grid .react-grid-Viewport .react-grid-Canvas::-webkit-scrollbar:horizontal {
  height: 11px; }

.react-grid-Grid .react-grid-Viewport .react-grid-Canvas::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5); }

.Polaris-PositionedOverlay {
  z-index: 999 !important; }

.chev-down-btn {
  cursor: pointer;
  margin: 10px 0;
  background-color: #efefef;
  padding: 15px 10px;
  border: none;
  display: flex;
  justify-content: center; }
  .chev-down-btn.block {
    width: 100%; }
  .chev-down-btn span {
    position: relative;
    text-transform: uppercase;
    font-size: 16px; }
    .chev-down-btn span::before {
      background-image: url("https://images.ctfassets.net/2uxxifu5nzlv/4QxqdHjhBMgTTx6cW9rZXf/8a4f32b2ea94cfac10cdafdb39932944/icon_tick_down_black.svg");
      background-repeat: no-repeat;
      background-size: 12px 12px;
      content: "";
      position: absolute;
      top: 0px;
      left: -43px;
      width: 40px;
      height: 100%;
      text-align: center;
      font-size: inherit;
      background-position: center; }
  .chev-down-btn.reverse span::before {
    transform: rotate(180deg); }
  .chev-down-btn.red span::before {
    background-image: url("./assets/icons/icon_red_arrow.svg");
    background-size: 22px 22px; }

.mx-auto {
  margin-right: auto;
  margin-left: auto; }

.w-full {
  width: 100%; }

.mt-3 {
  margin-top: 0.75rem; }

.pointer {
  cursor: pointer; }

.uppercase {
  text-transform: uppercase; }

.jnto-generic-field .field-item-block .field-items .field.text-field input {
  background-color: #EFEFEF;
  padding: 0px 10px;
  min-height: 3.1rem;
  color: #5c636a;
  border-radius: 5px; }

.jnto-generic-field .field-item-block .field-items .field-group.date-type {
  display: flex; }
  .jnto-generic-field .field-item-block .field-items .field-group.date-type .jnto-selector-container {
    margin-right: 8px; }

.jnto-generic-field .field-item-block .field-items .field-group .jnto-selector-container {
  position: relative; }

.jnto-generic-field .field-item-block .field-items .field-group .jnto-selector-container::before {
  padding: 0 12px; }

.jnto-generic-field .field-item-block .field-items .field-group select {
  background-color: #efefef;
  padding: 6px 35px 6px 8px; }

.Polaris-Button--primary.Polaris-Button--disabled {
  color: red !important;
  background: #efefef !important;
  opacity: .4 !important; }

.radio-group {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 20px 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create the indicator (the dot/circle - hidden when not checked) */ }
  .radio-group .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%; }
  .radio-group .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .radio-group.-disabled {
    color: #999;
    cursor: default; }

.reset-button {
  margin-left: 60px;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer; }

.radio-group input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.radio-group:not(.-disabled):hover input ~ .checkmark {
  background-color: #ccc; }

/* When the radio button is checked, add a blue background */
.radio-group:not(.-disabled) input:checked ~ .checkmark {
  background-color: #e7001d; }

/* Show the indicator (dot/circle) when checked */
.radio-group input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.radio-group .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e7001d; }

.generic-form {
  color: black;
  max-width: 450px;
  margin: auto; }

.jnto-selector-container {
  position: relative; }

.jnto-selector-container::before {
  background-image: url("./assets/icons/icon_red_arrow.svg");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  display: inline-block;
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  width: 9%;
  height: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #d7d7d7;
  pointer-events: none;
  padding: 0 20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background-position: center; }

.jnto-selector-container:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: #c8c7c7; }

.jnto-selector-container select option {
  padding: 30px; }

.jnto-selector-container-filter {
  height: 40px;
  width: 240px;
  border: none;
  border-radius: 7px;
  text-align: left;
  padding-left: 15px; }

.field-group {
  text-align: left; }
  .field-group label {
    display: block;
    padding: 15px 0; }
  .field-group input {
    border: none;
    outline: none;
    background-color: #efefef;
    border-radius: 7px;
    width: 100%;
    padding: 15px; }
  .field-group textarea {
    border: none;
    outline: none;
    width: 100%;
    background-color: #efefef;
    border-radius: 7px;
    resize: none;
    padding: 15px; }
  .field-group .jnto-selector-container {
    position: relative; }
  .field-group .jnto-selector-container::before {
    background-image: url("./assets/icons/icon_red_arrow.svg");
    background-repeat: no-repeat;
    background-size: 25px 25px;
    display: inline-block;
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    width: 9%;
    height: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.5);
    background-color: #d7d7d7;
    pointer-events: none;
    padding: 0 20px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background-position: center; }
  .field-group .jnto-selector-container:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: #c8c7c7; }
  .field-group .jnto-selector-container select option {
    padding: 30px; }
  .field-group select {
    padding: 15px;
    width: 100%;
    border-radius: 7px;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none; }
  .field-group select::-ms-expand {
    display: none;
    /* Remove default arrow in Internet Explorer 10 and 11 */ }
  .field-group .jnto-selector-container.inverted select {
    background-color: white; }

.checkbox-field-group {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
  width: 20px; }
  .checkbox-field-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .checkbox-field-group .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: solid 1px white;
    border-radius: 3px; }
  .checkbox-field-group .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .checkbox-field-group input:checked ~ .checkmark:after {
    display: block; }
  .checkbox-field-group .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .checkbox-field-group.inverted .checkmark {
    background-color: white; }
  .checkbox-field-group.inverted .checkmark:after {
    border: solid #e7001d;
    border-width: 0 3px 3px 0; }

.checkbox-label {
  padding-left: 5px; }

.toggle-btn-container {
  display: flex;
  align-items: center; }
  .toggle-btn-container .field-label {
    margin-left: 30px;
    margin-right: 40px; }
  .toggle-btn-container label {
    font-size: 13px;
    color: #424242;
    font-weight: 500; }
  .toggle-btn-container .btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative; }
  .toggle-btn-container .btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 140px;
    height: 30px;
    background: #ffffff;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
    display: block; }
  .toggle-btn-container .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    left: 25px;
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    color: gray; }
  .toggle-btn-container .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    left: 68px;
    background: gray;
    color: white;
    width: 70px;
    height: 26px;
    border-radius: 26px;
    position: absolute;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 3px 0px; }
  .toggle-btn-container .btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px; }
  .toggle-btn-container .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
    background: #ffffff;
    color: gray; }
  .toggle-btn-container .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 2px;
    background: #e7111d; }
  .toggle-btn-container .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    left: auto;
    right: 27px; }

.jnto-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10000; }
  .jnto-modal-wrapper.fixed {
    position: fixed; }
  .jnto-modal-wrapper .jnto-loader {
    z-index: 999; }
    .jnto-modal-wrapper .jnto-loader .icon {
      height: 100px; }
    .jnto-modal-wrapper .jnto-loader .content {
      margin-top: 15px;
      text-align: center;
      color: white; }
  .jnto-modal-wrapper.large {
    position: fixed;
    z-index: 10000; }

.upload-modal {
  max-width: 400px; }

.jnto-modal {
  z-index: 999;
  margin: auto;
  border-radius: 10px;
  min-width: 800px;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  padding: 48px 60px; }
  .jnto-modal h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px; }
  .jnto-modal .content {
    text-align: center;
    padding: 20px 0; }
    .jnto-modal .content p {
      line-height: 24px; }
  .jnto-modal .actions {
    display: flex;
    justify-content: center; }
  .jnto-modal .Polaris-Scrollable {
    max-width: 600px;
    margin: auto; }
  .jnto-modal.large {
    min-width: 80%;
    max-width: 900px;
    border-radius: 0;
    height: 80vh;
    padding: 15px; }
    .jnto-modal.large .close-modal {
      cursor: pointer;
      position: absolute;
      right: -15px;
      top: -15px;
      background-color: #000000;
      border-radius: 50%;
      padding: 10px;
      color: white;
      font-weight: bold;
      line-height: 12px; }
    .jnto-modal.large .content {
      height: 100%; }
      .jnto-modal.large .content .asset-detail-row {
        height: 100%; }

.jnto-modal.forward {
  z-index: 10001; }

.jnto-modal-wrapper:after {
  content: "";
  position: absolute;
  background-color: #000000;
  opacity: .8;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 800; }

.jnto-modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2); }

.jnto-modal .content {
  padding: 1rem; }

.generic-modal .actions {
  margin-top: 20px; }

.dashboard-page {
  padding: 40px 30px; }
  .dashboard-page .dashboard-item-container {
    display: flex;
    flex-wrap: wrap; }
    .dashboard-page .dashboard-item-container a {
      text-decoration: none;
      color: #292c2f; }
      .dashboard-page .dashboard-item-container a:hover {
        cursor: pointer;
        color: #e7001d; }
    .dashboard-page .dashboard-item-container .dashboard-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 20px 50px;
      margin-right: 15px;
      width: 200px;
      height: 150px;
      margin-top: 15px;
      text-align: center; }
      .dashboard-page .dashboard-item-container .dashboard-item img {
        width: 50px;
        height: 50px; }
      .dashboard-page .dashboard-item-container .dashboard-item h3 {
        margin-top: 5px; }

.main-folder-banner {
  width: 100%;
  background-color: #ffffff;
  padding: 30px 30px; }
  .main-folder-banner .bold {
    font-weight: bold; }
  .main-folder-banner .form-section {
    max-width: 400px;
    margin-top: 15px;
    display: flex;
    align-items: center; }
    .main-folder-banner .form-section .field-group {
      flex: 1; }
  .main-folder-banner .actions-section {
    margin-top: 15px;
    display: flex;
    align-items: center; }
    .main-folder-banner .actions-section .dui-btn {
      margin: 10px 15px 10px 0; }
  .main-folder-banner .top-content {
    display: flex;
    justify-content: space-between; }
    .main-folder-banner .top-content a {
      color: inherit;
      text-decoration: none;
      position: relative; }
      .main-folder-banner .top-content a:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -3px;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #9d9d9d; }

.toggle-container .custom-toggle-switch {
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  width: 500px;
  height: 48px; }
  .toggle-container .custom-toggle-switch.slim {
    width: 100px;
    margin: 0;
    height: 26px; }
  .toggle-container .custom-toggle-switch input#status, .toggle-container .custom-toggle-switch input.status {
    display: none; }
    .toggle-container .custom-toggle-switch input#status + label, .toggle-container .custom-toggle-switch input.status + label {
      height: 100%;
      width: 100%; }
      .toggle-container .custom-toggle-switch input#status + label > .status-switch, .toggle-container .custom-toggle-switch input.status + label > .status-switch {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;
        background-color: white;
        color: #292c2f;
        transition: all 0.5s ease;
        padding: 3px;
        border-radius: 30px; }
        .toggle-container .custom-toggle-switch input#status + label > .status-switch:before, .toggle-container .custom-toggle-switch input#status + label > .status-switch:after, .toggle-container .custom-toggle-switch input.status + label > .status-switch:before, .toggle-container .custom-toggle-switch input.status + label > .status-switch:after {
          border-radius: 2px;
          height: calc(100% - 6px);
          width: calc(50% - 3px);
          display: flex;
          align-items: center;
          position: absolute;
          justify-content: center;
          transition: all 0.3s ease; }
        .toggle-container .custom-toggle-switch input#status + label > .status-switch:before, .toggle-container .custom-toggle-switch input.status + label > .status-switch:before {
          background-color: white;
          color: white !important;
          left: 3px;
          z-index: 10;
          content: attr(data-unchecked);
          background-color: #e7001d;
          border-radius: 30px; }
        .toggle-container .custom-toggle-switch input#status + label > .status-switch:after, .toggle-container .custom-toggle-switch input.status + label > .status-switch:after {
          right: 0;
          content: attr(data-checked); }
    .toggle-container .custom-toggle-switch input#status:checked + label > .status-switch, .toggle-container .custom-toggle-switch input.status:checked + label > .status-switch {
      background-color: white; }
      .toggle-container .custom-toggle-switch input#status:checked + label > .status-switch:after, .toggle-container .custom-toggle-switch input.status:checked + label > .status-switch:after {
        left: 0;
        content: attr(data-unchecked); }
      .toggle-container .custom-toggle-switch input#status:checked + label > .status-switch:before, .toggle-container .custom-toggle-switch input.status:checked + label > .status-switch:before {
        color: white;
        left: 50%;
        content: attr(data-checked); }

.toggle-container-multi .mutliInput:checked + .toggle {
  color: white; }

.toggle-container-multi .tw-toggle {
  /* background: #95A5A6; */
  display: flex;
  padding: 2px 3px;
  border-radius: 30px;
  position: relative;
  border: none;
  width: 750px;
  height: 48px;
  background-color: white; }

.toggle-container-multi .tw-toggle label {
  cursor: pointer;
  text-align: center;
  font-family: sans-serif;
  left: -50px;
  display: block;
  color: #95A5A6;
  position: relative;
  z-index: 2;
  margin: 0;
  text-align: center;
  padding: 2px 3px;
  font-size: 15px;
  height: 48px;
  width: 400px;
  top: 22%;
  position: relative;
  /* cursor: pointer; */ }
  .toggle-container-multi .tw-toggle label .toggle_multi_label {
    left: 65px;
    width: 250px;
    top: 22%;
    pointer-events: inherit;
    position: relative; }

.toggle-container-multi .tw-toggle input {
  /* display: none; */
  position: relative;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
  height: 48px;
  width: 250px; }

.toggle-container-multi .tw-toggle span {
  height: 40px;
  background: #e7001d;
  width: 250px;
  line-height: 21px;
  border-radius: 30px;
  color: #fff;
  display: block;
  position: absolute;
  left: 22px;
  top: 4px;
  transition: all 0.3s ease-in-out; }

.toggle-container-multi .tw-toggle input[value="edit"]:checked ~ span {
  left: 4px; }

.toggle-container-multi .tw-toggle input[value="create"]:checked ~ span {
  left: 246px; }

.toggle-container-multi .tw-toggle input[value="remove"]:checked ~ span {
  left: 496px; }

.toggle-container-multi .custom-toggle-switch {
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  width: 750px;
  height: 48px; }

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%; }
  table td, table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px; }
  table tr:first-child {
    background-color: #dddddd; }

.commented-out {
  color: grey; }

.faq-question {
  font-weight: bold; }

* {
  margin: 0;
  padding: 0; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

div {
  white-space: pre-line; }

.react-grid-Cell {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }
